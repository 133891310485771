<template>
  <div>
    <reminder
      v-if="this.pageData"
      style="margin-bottom: 24px"
      class="reminder"
      :titleArray="['数据公告']"
      :text-arr="tips"
    ></reminder>
    <SourceScreen :filterForm="filterForm" :uploadFilter="ok"> </SourceScreen>
    <div class="brand-content" v-loading="show_loading">
      <el-table
        class="thead-light"
        :data="list"
        tooltip-effect="dark"
        stripe
        @selection-change="handleSelectionChange"
        @sort-change="sortChange"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <!-- <el-table-column prop="id" label="ID" min-width="70"></el-table-column> -->

        <el-table-column
          prop="nickname"
          label="用户名称"
          min-width="110"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.nickname | placeholder }}
          </template>
        </el-table-column>
        <el-table-column
          prop="title_name"
          label="会内职务"
          min-width="200"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.title_name | placeholder }}
          </template>
        </el-table-column>
        <el-table-column
          prop="user_invite_count"
          label="邀请次数"
          min-width="130"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.user_invite_count | placeholder }}
          </template>
        </el-table-column>
        <el-table-column
          prop="total_count"
          label="邀请人数"
          min-width="130"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.total_count | placeholder }}
          </template>
        </el-table-column>
      
        <el-table-column
          prop="second_user_invite_count"
          label="扩散次数"
          min-width="130"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.second_user_invite_count | placeholder }}
          </template>
        </el-table-column>
        <el-table-column
          prop="second_count"
          label="扩散人数"
          min-width="130"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.second_count | placeholder }}
          </template>
        </el-table-column>
        <el-table-column
          prop="month_money"
          label="本月收益(元)"
          min-width="130"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.month_money | placeholder }}
          </template>
        </el-table-column>
        <el-table-column
          prop="month_integrals"
          label="本月收益(积分)"
          min-width="130"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.month_integrals | placeholder }}
          </template>
        </el-table-column>
        <el-table-column
          prop="user_invite_money"
          label="累计收益(元)"
          min-width="130"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.user_invite_money | placeholder }}
          </template>
        </el-table-column>
        <el-table-column
          prop="user_invite_integrals"
          label="累计收益(积分)"
          min-width="130"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.user_invite_integrals | placeholder }}
          </template>
        </el-table-column>
        <el-table-column
          prop="money"
          label="账户余额"
          min-width="130"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.money | placeholder }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="100">
          <template slot-scope="{ row }">
            <el-button @click="checkDetail(row.id)" type="text">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      v-show="!show_loading"
      :total="pageData.total"
      :page="pageData.current_page"
      :change="changPage"
    />
  </div>
</template>

<script>
import SourceScreen from "../components/benefits/SourceScreen";
import Pagination from "@/base/components/Default/Pagination";
import { getDetail } from "../../activity/api/cyc-activity-order";
import ListStatusText from "@/base/components/List/ListStatusText";
import Reminder from "../components/Reminder";
import { getMemberList } from "../api/benefits/benefits.js";

export default {
  components: {
    SourceScreen,
    Pagination,
    ListStatusText,
    Reminder,
  },
  data() {
    return {
      //  温馨提示
      tips: [
        [
          "截止目前：",
          `本月的成功邀请会员加入：0（个），最近三个月成功邀请会员加入：0（个），本届成功邀请会员加入：0（个）`,
        ],
        []
        // [
        //   "如需使用提现功能，需先开通商户号的商家转账到零钱的功能",
        //   "如需开通商户号，需到微信支付的商户平台进行开通",
        //   "如已开通商户号，需开通商家转账到零钱的功能，需到微信支付商户平台-产品中心，开通商家转账到零钱",
        // ],
      ],
      show_loading: false,
      showTransfer: false,
      dialogLoading: false,
      // 会员收益列表
      list: [],
      total: 0,
      detailList: [],
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        page: 1,
        page_size: 15,
        name: "",
      },
      pageData: {},
      selectArr: [],
    };
  },
  created() {
    this.getIndexList(this.filterForm);
  },
  methods: {
    // 查看用户详情
    checkDetail(id) {
      console.log(id);
      // 跳转会员收益详情
      this.$router.push({
        name: "CycInviteMembersDetail",
        params: {
          id,
        },
      });
    },
    //页面切换
    changPage(data) {
      this.filterForm.page_size = data.page_size;
      const pageData = { ...this.filterForm, ...data };
      this.getIndexList(pageData);
    },
    //获取列表
    getIndexList(requestData) {
      this.show_loading = true;
      this.pageData = {};
      // 获取列表
      getMemberList(requestData)
        .then((res) => {
          const { data } = res;
          this.list = data.data;
          console.log(res);
          this.pageData = data;
          (this.tips = [
            [
              "截止目前：",
              `本月的成功邀请会员加入：${this.pageData?.month_count}（个），最近三个月成功邀请会员加入：${this.pageData?.recent_count}（个），本届成功邀请会员加入：${this.pageData?.term_count}（个）`,
            ],
            []
            // [
            //   "如需使用提现功能，需先开通商户号的商家转账到零钱的功能",
            //   "如需开通商户号，需到微信支付的商户平台进行开通",
            //   "如已开通商户号，需开通商家转账到零钱的功能，需到微信支付商户平台-产品中心，开通商家转账到零钱",
            // ],
          ]),
            (this.show_loading = false);
        })
        .catch((err) => {
          this.show_loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 筛选回调
    ok(e) {
      this.filterForm = {...e,page:1};
      this.getIndexList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getIndexList(this.filterForm);
    },
    updataDetail(id, status) {
      this.showTransfer = true;
      this.dialogLoading = true;
      getDetail({ id: id, status: status })
        .then((res) => {
          this.detailList = res.data;
          this.dialogLoading = false;
        })
        .catch((err) => {
          this.dialogLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-content {
  margin-top: 20px;
  .el-col-8 {
    height: 60px;
    display: flex;
    align-items: center;
  }
  .el-divider--horizontal {
    margin: 0;
  }
  .el-title {
    width: 70px;
  }
}
</style>
