<template>
  <LayoutFilter
    :onFilter="ok"
    :toggleCollapseEnable="false"
    :onReset="resetForm"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
    >
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="会员名称：" prop="name">
          <el-input
            v-model="form.name"
            placeholder="请输入会员名称"
          ></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker
          label="统计日期："
          start-prop="start_time"
          :start-time.sync="form.start_time"
          end-prop="end_time"
          :end-time.sync="form.end_time"
        />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { documentOrder } from "../../api/cyc-activity-order.js";
import { aElementDownload } from "@/base/utils/tool";

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    // onExport: {
    //   type: Function,
    // },
  },
  data() {
    return {
      rules: {},
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
    // 导出
    // onExport() {
    //   const postData = { ...this.form, ids: this.selectArr, page: this.page };
    //   return documentOrder(postData)
    //     .then(([blob, fileName]) => {
    //       aElementDownload(blob, fileName);
    //     })
    //     .catch(() => {});
    // },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    type: {
      get() {
        return this.form.type == -2 ? "" : this.form.type;
      },
      set(val) {
        this.form.type = val;
      },
    },
    category: {
      get() {
        return this.form.category == -2 ? "" : this.form.category;
      },
      set(val) {
        this.form.category = val;
      },
    },
    status: {
      get() {
        return this.form.status == -2 ? "" : this.form.status;
      },
      set(val) {
        this.form.status = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
