var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.pageData)?_c('reminder',{staticClass:"reminder",staticStyle:{"margin-bottom":"24px"},attrs:{"titleArray":['数据公告'],"text-arr":_vm.tips}}):_vm._e(),_c('SourceScreen',{attrs:{"filterForm":_vm.filterForm,"uploadFilter":_vm.ok}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.show_loading),expression:"show_loading"}],staticClass:"brand-content"},[_c('el-table',{staticClass:"thead-light",attrs:{"data":_vm.list,"tooltip-effect":"dark","stripe":""},on:{"selection-change":_vm.handleSelectionChange,"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"50"}}),_c('el-table-column',{attrs:{"prop":"nickname","label":"用户名称","min-width":"110","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(row.nickname))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"title_name","label":"会内职务","min-width":"200","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(row.title_name))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"user_invite_count","label":"邀请次数","min-width":"130","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(row.user_invite_count))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"total_count","label":"邀请人数","min-width":"130","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(row.total_count))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"second_user_invite_count","label":"扩散次数","min-width":"130","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(row.second_user_invite_count))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"second_count","label":"扩散人数","min-width":"130","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(row.second_count))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"month_money","label":"本月收益(元)","min-width":"130","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(row.month_money))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"month_integrals","label":"本月收益(积分)","min-width":"130","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(row.month_integrals))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"user_invite_money","label":"累计收益(元)","min-width":"130","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(row.user_invite_money))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"user_invite_integrals","label":"累计收益(积分)","min-width":"130","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(row.user_invite_integrals))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"money","label":"账户余额","min-width":"130","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(row.money))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作","fixed":"right","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.checkDetail(row.id)}}},[_vm._v("查看")])]}}])})],1)],1),_c('Pagination',{directives:[{name:"show",rawName:"v-show",value:(!_vm.show_loading),expression:"!show_loading"}],attrs:{"total":_vm.pageData.total,"page":_vm.pageData.current_page,"change":_vm.changPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }