<template>
  <div class="main reminder">
    <el-row v-for="(title,titleIndex) in titleArray" :key="title" style="padding: 20px">
      <el-col :span="24">
        <div class="main-title">
          <img
            style="width: 28px; height: 28px; margin-right: 5px"
            src="@/modules/system-admin/assets/images/icon-title.png"
          />
          {{ title }}
        </div>
      </el-col>
      <div class="main-context" v-if="textArr[titleIndex].length && hrefConfig">
        <el-row v-for="(item, i) in textArr[titleIndex]" :key="i">
          <el-col :span="23">
            <span>·</span>
            <div
              v-if="
                typeof item === 'string' && item.indexOf(hrefConfig.text) !== -1
              "
            >
              {{ item.match(new RegExp("([\\S\\s]*)" + hrefConfig.text))[1] }}
              <a :href="hrefConfig.url" target="_blank">{{
                hrefConfig.text
              }}</a>
              {{ item.match(new RegExp(hrefConfig.text + "([\\S\\s]*)"))[1] }}
            </div>
            <div
              v-if="
                typeof item === 'string' && item.indexOf(hrefConfig.text) === -1
              "
            >
              {{ item }}
            </div>
            <div v-if="typeof item !== 'string'">
              <div v-if="item[0].indexOf(hrefConfig.text) !== -1">
                {{
                  item[0].match(new RegExp("([\\S\\s]*)" + hrefConfig.text))[1]
                }}
                <a :href="hrefConfig.url" target="_blank">{{
                  hrefConfig.text
                }}</a>
                {{
                  item[0].match(new RegExp(hrefConfig.text + "([\\S\\s]*)"))[1]
                }}
              </div>
              <div v-else>{{ item[0] }}</div>
              <el-col
                class="col-leaf"
                :span="23"
                v-for="(col, j) in item.filter((obj, objIndex) => objIndex > 0)"
                :key="j"
              >
                <span>·</span>
                <div v-if="col.indexOf(hrefConfig.text) !== -1">
                  {{
                    col.match(new RegExp("([\\S\\s]*)" + hrefConfig.text))[1]
                  }}
                  <a :href="hrefConfig.url" target="_blank">{{
                    hrefConfig.text
                  }}</a>
                  {{
                    col.match(new RegExp(hrefConfig.text + "([\\S\\s]*)"))[1]
                  }}
                </div>
                <div v-else>{{ col }}</div>
              </el-col>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="main-context" v-if="textArr[titleIndex].length && !hrefConfig">
        <el-row v-for="(item, i) in textArr[titleIndex]" :key="i">
          <el-col :span="23">
            <span>·</span>
            <div v-if="typeof item === 'string'">{{ item }}</div>
            <div v-if="typeof item !== 'string'">
              <div>{{ item[0] }}</div>
              <el-col
                class="col-leaf"
                :span="23"
                v-for="(col, j) in item.filter((obj, objIndex) => objIndex > 0)"
                :key="j"
              >
                <span>·</span>
                <div>{{ col }}</div>
              </el-col>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="main-context" v-if="!textArr[titleIndex].length">
        <el-col :span="23">
          <div v-if="hrefConfig">
            {{ text.match(new RegExp("([\\S\\s]*)" + hrefConfig.text))[1] }}
            <a :href="hrefConfig.url" target="_blank">{{ hrefConfig.text }}</a>
            {{ text.match(new RegExp(hrefConfig.text + "([\\S\\s]*)"))[1] }}
          </div>
          <div v-else>{{ text }}</div>
        </el-col>
        <slot />
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    // 组件标题
    titleArray: {
      type: Array,
      default: ["温馨提示"],
    },
    // 组件内容数组
    textArr: {
      type: Array,
      default: () => [[]],
    },
    // 组件单行内容字符
    text: {
      type: String,
      default: "",
    },
    // 组件内容跳转第三方配置
    hrefConfig: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      hrefText: "微信公众平台",
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  background: #ebf1ff;
  border: 1px solid #d7e4ff;
  border-radius: 2px;
  margin-bottom: 20px;
}
.main-title {
  color: #3576ff;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.main-context {
  font-size: 14px;
  line-height: 24px;
  ::v-deep.el-col-23 {
    display: flex;
    margin-left: 30px;
    max-width: 883px;
    span {
      line-height: 24px;
      padding-right: 10px;
    }
  }

  ::v-deep.reminder-link {
    text-decoration: none;
    color: #3576ff;
  }
}
.col-leaf {
  margin-left: 0 !important;
}
a {
  text-decoration: none;
  color: #3576ff;
}
</style>
